import '@formkit/themes/genesis'
import '@formkit/pro/genesis'
import { createApp } from 'vue'
import App from './surveyClient.vue'
import { createProPlugin, taglist } from '@formkit/pro'
import { plugin, defaultConfig } from '@formkit/vue'
import { de } from '@formkit/i18n'

const app = createApp(App)
app.config.performance = true;
const pro = createProPlugin('fk-62a3a589c3', { taglist })
app.use(
    plugin,
    defaultConfig({
        //icons: { ...genesisIcons },
        locales: { de },
        locale: 'de',
        plugins: [pro],
        messages: {
            de: {
                validation: {
                    required() {
                        return `Pflichtfeld`
                    }
                }
            }
        }
    })
)
app.mount('#app')
