<template>
  <div id="header">
    <div class="logo">
      <img src="https://www.bethel-regional.de/files/bethel_theme/layout_bilder/Bethel_plusBregional_logo-print.svg" alt="Logo: Bethel regional">
    </div>
    <div class="pageTitle"><h1>Anfragebogen für die Aufnahme in einem Heimathof</h1></div>
  </div>
  <div id="main">
    <div v-if="rId !== 0">
      <surveyForm v-bind:url="url" v-bind:rId="rId" v-bind:survey="presetSurvey" />
    </div>
    <div v-else class="message-warning">Der von Ihnen aufgerufene Fragebogen ist  bereits ausgefüllt oder Ihr Registrierungslink ist abgelaufen!</div>
  </div>

  <div id="footer">
    <div class="mod_article_as_module footer__main block">
      <div class="footer__content">
        <div class="ce_image footer__logo block">
          <figure class="image_container">
            <img src="https://www.bethel-regional.de/files/bethel_theme/layout_bilder/bethel-regional_logo_weiss.svg" alt="Logo: Bethel.regional" title="Bethel.regional" itemprop="image">
          </figure>
        </div>
        <div class="footer__infos">
          <p>Bei Fragen können Sie sich jederzeit gerne an uns wenden: <a href="mailto:heimathof@bethel.de">heimathof@bethel.de</a></p>
          <p>Oder Sie rufen uns an unter:</p>
          <ul>
            <li>Heimathof Ruhr Hagen: <a href="tel:02334500740">02334 50074-0</a></li>
            <li>Heimathof Ruhr Gelsenkirchen: <a href="tel:02093597460">0209 359746-0</a></li>
            <li>Heimathof Ruhr Castrop-Rauxel: <a href="tel:02305548880">02305 548880</a></li>
          </ul>
        </div>
      </div>
      <div class="footer__legal-content">
        <p class="footer__copyright">© 2024 - Bethel.regional</p>
        <nav class="mod_customnav footer__navi block" itemscope="" itemtype="http://schema.org/SiteNavigationElement">
          <ul class="level_1">
            <li class="first"><a href="https://www.bethel-regional.de/impressum.html" title="Impressum" class="first" itemprop="url"><span itemprop="name">Impressum</span></a></li>
            <li><a href="https://www.bethel-regional.de/datenschutz.html" title="Datenschutz" itemprop="url"><span itemprop="name">Datenschutz</span></a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<script>
import surveyForm from './components/surveyForm.vue'
import axios from "axios"

export default {
  name: "surveyClient",
  title: "Bethel Anfragebogen",
  components: {
    surveyForm,
  },
  async created() {
    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('rId')) {
      if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(urlParams.get('rId'))) {
        this.rId = urlParams.get('rId')
      }
    }

    if (this.rId !== 0) {
      try {
        const survey = await axios.get(this.url + 'api/results/' + this.rId)
        this.sections = survey.data.quiz.quizSections
        this.answerValues = JSON.parse(survey.data.answers)
        for (const key in this.answerValues) {
          for (const subkey in this.answerValues[key]) {
            this.presetValues[subkey] = this.answerValues[key][subkey]
          }
        }

        for (let sectionKey = 0; sectionKey < this.sections.length; sectionKey++) {
          let sectionQuestions = JSON.parse(this.sections[sectionKey].questions)

          for (let questionKey = 0; questionKey < sectionQuestions.length; questionKey++) {
            sectionQuestions[questionKey]["value"] = ''
            for (let valueKey in this.presetValues) {
              if (sectionQuestions[questionKey]["name"] === valueKey) {
                sectionQuestions[questionKey]["value"] = this.presetValues[valueKey]
              }
            }
            this.sections[sectionKey].questions = JSON.stringify(sectionQuestions)
          }
        }

        this.presetSurvey = this.sections.reverse()
      } catch (err) {
        if (err) {
          this.rId = 0
          //console.log(err.message)
          console.log('Bei der Anfrage der Daten ist etwas schiefgegangen.')
        }
      }
    }
  },
  mounted() {
    document.title = this.title
  },
  data() {
    return {
      rId:0,
      url:'https://survey.netgroup.de/',
      title:'Bethel Anfragebogen',
      presetSurvey: [{ name: '', questions: '{}'}],
      presetValues: [{ }],
    }
  },
}
</script>

