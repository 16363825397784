import { reactive, toRef, ref } from 'vue'

export default function useSteps () {
    const activeStep = ref('')
    const steps = reactive({})
    const visitedSteps = ref([]) // track visited steps

    const setStep = (delta) => {
        const stepNames = Object.keys(steps)
        const currentIndex = stepNames.indexOf(activeStep.value)
        activeStep.value = stepNames[currentIndex + delta]
    }
    const stepPlugin = (node) => {
        if (node.props.type == "group" && node.name !== "group_2") {
            // Die Reiter erzeugen
            steps[node.name] = steps[node.name] || {}

            node.on('created', () => {
                steps[node.name].valid = toRef(node.context.state, 'valid')
            })

            // Anzahl der Validierungsfehler je Abschnitt
            node.on('count:blocking', ({ payload: count }) => {
                steps[node.name].blockingCount = count
            })

            node.on('count:errors', ({ payload: count }) => {
                steps[node.name].errorCount = count
            })

            if (activeStep.value === '') {
                activeStep.value = node.name
            }

            // Stop plugin inheritance to descendant nodes
            return false
        }
    }

    return { activeStep, steps, setStep, stepPlugin, visitedSteps }
}
