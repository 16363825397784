<script>
export default {
  name: 'surveyForm',
  methods: {
    submitForm() {
      this.$formkit.submit('surveyForm')
    },
  }
}
</script>

<script setup>
import { defineProps, ref } from 'vue'
import useSteps from '../useSteps.js'
import axios from "axios"
const { steps, visitedSteps, activeStep, setStep, stepPlugin } = useSteps()

const props = defineProps(['url', 'rId', 'survey'])

const message = ref("")
const finished = ref(0)
const progress = ref(0)
const upload = ref(0)
const uploadError = ref(0)
const uploadErrorMessage = ref('')
const uploadFilesize = ref(0)

const checkStepValidity = (stepName) => {
  return (steps[stepName].errorCount > 0)  && visitedSteps.value.includes(stepName)
}

const checkReadyForUpload = (stepName, lastStep, valid) => {
  return (stepName === lastStep) && !valid
}

function setMessage(text) {
  message.value=text
  setTimeout(() => {
    setMessage("")
  }, 2000)
}

function setUploadMessage(text) {
  uploadErrorMessage.value=text
  setTimeout(() => {
    uploadError.value = 0
    setUploadMessage("")
  }, 3000)
}

const finalResults = async(formData, node) => {
  uploadError.value = 0
  uploadErrorMessage.value = ''
  uploadFilesize.value  = 0

  if (formData["Dokumenten Upload"].file.length > 0) {
    const files = formData["Dokumenten Upload"].file

    const fileUpload = new FormData();
    files.forEach((fileitem) => {
      fileUpload.append('file[]', fileitem.file)
      fileUpload.append('result', props.rId)
      uploadFilesize.value = uploadFilesize.value + fileitem.file.size
    })

    if (uploadFilesize.value < 10000000) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          progress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
      };

      try {
        upload.value = 1;
        formData["Dokumenten Upload"].file = {}

        const results = {
          rId: props.rId,
          isClosed: true,
          answers: JSON.stringify(formData)
        };

        const resFileUpload = await axios.post(props.url + 'api/media_objects', fileUpload, config)

        const resFormData = await axios.post(props.url + 'send-quiz-results', results, {headers: {'Content-Type': 'multipart/form-data'}})

        console.log(resFileUpload.status)
        console.log(resFormData.status)
        node.clearErrors()

        finished.value = 1
        upload.value = 0
      } catch (err) {
        upload.value = 0
        uploadError.value = 1
        setUploadMessage('Bei der Übertragung der Daten ist etwas schiefgegangen.')
        //console.log(err.message)
      }
    } else {
      uploadError.value = 1
      setUploadMessage('Die ausgewählten Datei(en) überschreiten die maximale Dateigröße von 10 MB.')
    }
  } else {
    uploadError.value = 1
    setUploadMessage( 'Bitte fügen Sie Fotos Ihrer Dokumente hinzu.')
  }
}

const tempResults = async(formData) => {
  try {
    const results = {
      rId: props.rId,
      isClosed: false,
      answers: JSON.stringify(formData.value)
    };

    const res = await axios.post(props.url + 'send-quiz-results', results, {headers: { 'Content-Type' : 'multipart/form-data'}})
    console.log(res.request.status)
    setMessage("Die Daten wurden erfolgreich zwischengespeichert")
  } catch (err) {
    console.log(err.message)
  }
}

function scrollToTop() {
  window.scrollTo(0,0);
}
</script>

<template>
  <div v-if="!finished">
    <FormKit
        type="form"
        id="surveyForm"
        #default="{ value , state: { valid } }"
        :plugins="[stepPlugin]"
        :actions="false"
        @submit="finalResults"
        @submit-invalid="tempResults"
        :incomplete-message=false
    >

      <ul class="steps">
        <!-- Hier werden die Reiter je Abschnitt aufgebaut -->
        <li
            v-for="(step, stepName) in steps"
            :key="step['@id']"
            :class="['step', { 'has-errors': checkStepValidity(stepName), 'step--not-active': checkReadyForUpload(stepName,survey[0].name,valid)  }]"
            @click="activeStep = stepName; scrollToTop();submitForm()"
            :data-step-valid="step.valid && step.errorCount === 0"
            :data-step-active="activeStep === stepName"
            :title="stepName"
        >
          <!-- NEW: output total number of errors in a little red bubble -->
          <span
              v-if="step.blockingCount > 0 && activeStep !== stepName"
              class="step--errors"
              v-text="step.blockingCount"
          />
          <span v-else class="step--icon" role="presentation">
          </span>

          <!-- Label des Abschnittes -->
          <span class="step--label" :title="stepName">{{ stepName }}</span>
        </li>
      </ul>

      <div class="form-body">
          <section
              v-for="section in survey"
              :key="section['@id']" v-show="activeStep === section.name" class="section">
            <!-- Abschnitttitel -->
            <h2>{{ section.name }}</h2>

            <FormKit
                type="group"
                :id=section.name
                :name=section.name
            >
              <FormKitSchema :schema=JSON.parse(section.questions) />
            </FormKit>

            <!-- Abschnitt Beschreibung kommt später aus der DB -->
            <div>{{ section.description }}</div>
          </section>

          <!-- Hier sind die Navigationsbutton Seite vor / Seite zurück -->
          <div class="step-nav">
            <FormKit type="button" outer-class="previous-btn" :disabled="activeStep === survey[survey.length-1].name" @click="setStep(-1);scrollToTop();submitForm()" v-text="'Vorheriger Abschnitt'" />
            <FormKit v-if="valid" type="button" outer-class="next-btn" :disabled="activeStep === survey[0].name" @click="setStep(1);scrollToTop();submitForm()" v-text="'Nächster Abschnitt'"/>
            <FormKit v-else type="button" outer-class="next-btn" :disabled="activeStep === survey[1].name" @click="setStep(1);scrollToTop();submitForm()" v-text="'Nächster Abschnitt'"/>
          </div>

          <!-- Hier ist der Senden Button -->
          <div v-if="valid && !upload">
            <div v-if="uploadError" class="message-warning">{{ uploadErrorMessage }}</div>
            <FormKit v-else type="button" outer-class="send-btn" label="Formular senden" @click="submitForm" />
          </div>

          <div v-if="!valid">
            <!-- Hier ist die Meldung falls Zwischengespeichert wurde -->
            <div v-if="message" class="message">{{ message }}</div>
            <!-- Hier ist der Speicherbutton für Zwischenspeichern -->
            <FormKit v-else type="button" outer-class="save-btn" v-show="activeStep !== survey[survey.length-1].name" label="Daten für spätere Bearbeitung zwischenspeichern" @click="submitForm" />
          </div>

        <pre v-if="upload">Dokumentenupload zu {{ progress }} % durchgeführt-</pre>
        <details>
          <summary>Form data</summary>
          <pre>{{ value }}</pre>
        </details>
      </div>
    </FormKit>
  </div>
  <div v-else class="message-success"><h2>Sie haben den Fragenbogen erfolgreich ausgefüllt und die Daten wurden übertragen!</h2></div>
</template>




<style>
:root {
  --gray: #ccccd7;
  --gray-l: #eeeef4;
  --col1: #102045;
  --col2: #E6EEF1;
  --col3: #0ff;
  --color-danger: #ea0000;
  --color-warning: #FAA00F;
  --color-success: #00CD37;
  --fk-bg-button: #102045;
  --fk-font-size-legend: 1rem;
  --fk-font-size-label: 1rem;
  --fk-font-size-option: 1rem;
  --fk-color-primary: var(--col1);
  --fk-color-error: var(--color-danger);
  --fk-bg-button-hover: white;
  --col-button-hover: var(--col1);

}
html {
  font-size: 20px;
}
body {
  font-family: "Hind", Arial, sans-serif;
  color: var(--col1);
  font-size: 1rem;
  line-height: 180%;
  margin: 0;

  --multistep-color-success: #102045;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app > h1 {
  display: none;
}
#header {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}
.logo  {
  max-width: 200px;
  width: 100%;
}
.logo img {
  display: block;
}
#header .pageTitle {
  max-width: calc(100% - 250px);
}
#header h1 {
  margin: 0;
  text-align: right;
}
h1 {
  font-size: 2.4rem;
  line-height: 120%;
  font-weight: normal;
  text-align: center;
}

#main {
  padding-left: 20px;
  padding-right: 20px;
  flex: 1;
}

.formkit-form {
  max-width: 100%;
  display: flex;
  width: 100%;
  gap: 20px;
  margin-top: 1em;
  margin-bottom: 1em;
  align-items: flex-start;
}

.form-body {
  border: 1px solid var(--gray);
  border-radius: 0.5em;
  box-shadow: 0.25em 0.25em 1em 0 rgba(0,0,0,0.1);
  padding: 2em;
  width: 100%;
  box-sizing: border-box;
}

section > h2:first-child {
  margin-top: 0;
}

[data-type="checkbox"] fieldset.formkit-fieldset, [data-type="radio"] fieldset.formkit-fieldset {
  padding-left: 0;
  padding-right: 0;
  border: 0;
  width: 100%;
  max-width: 100%;
}

.formkit-legend {
  padding: 0;
}
[data-type="button"] .formkit-input {
  transition: all 0.3s ease-in-out;
}
[data-type="button"] .formkit-input:hover {
  border-color: var(--col1);
  color: var(--col1);
}

.steps {
  list-style-type: none;
  margin: 0;
  display: flex;
  padding-left: 0;
  overflow: hidden;
  width: 25vw;
  gap: 0;
  flex-direction: column;
  min-width: 35px;
}

.step {
  height: auto;
  width: 100%;
  text-align: left;
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
}

.step:hover { cursor: pointer; }

.step:before {
  background: #fff;
  border: 5px solid var(--gray);
  border-radius: 999em;
  content: "";
  display: block;
  margin-bottom: .5rem;
  z-index: 2;
  flex-shrink: 0;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
}

.step:after {
  background: var(--gray);
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  left: 13px;
  top: 10px;
  width: 5px;
}

.step:last-child:after {
  display: none;
}

.step--label {
  font-size: 0.8rem;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.step--not-active {
  pointer-events: none;
  color: #999999;
}

[data-step-active="true"] .step--label{
  font-weight: bold;
}

.step--errors, .step--icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: .25rem;
  font-size: .66rem;
  border-radius: 999em;
  color: #fff;
  border: 5px solid;
  box-sizing: border-box;
}

.step--icon {
  border-color: transparent;
}

[data-step-active="true"] .step--icon {
  background: var(--multistep-color-success);
  border-color: var(--multistep-color-success);
}

.step--errors {
  background: var(--color-danger);
  border-color: var(--color-danger);
}

.step-nav {
  display: flex;
  margin-top: 2em;
  margin-bottom: 1em;
  justify-content: space-between;
}
.step-nav button {
  display: inline-block;
}
.step-nav button:after {
  content: "";
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
}
.next-btn button:after {
  content: url("../assets/arrow-right-solid.svg");
}
.previous-btn button:after {
  display: none;
}
.previous-btn button:before {
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
  flex-shrink: 0;
  display: inline-block;
  content: url("../assets/arrow-right-solid.svg");
  transform: rotate(-180deg);
  align-self: end;
}

.send-btn button:after {
  width: 1rem;
  height: 1rem;
  margin-left: 10px;
  display: inline-block;
  flex-shrink: 0;
  content: url("../assets/paper-plane-solid.svg");
  align-self: end;
}

.save-btn button:before{
  width: 1rem;
  height: 1rem;
  margin-right: 10px;
  flex-shrink: 0;
  content: url("../assets/floppy-disk-solid.svg");
}

.next-btn button:after, .previous-btn button:before,
.send-btn button:after, .save-btn button:before {
  filter: invert(98%) sepia(7%) saturate(117%) hue-rotate(3deg) brightness(119%) contrast(100%);
  transition: all 0.3s ease-in-out;
}

.next-btn button:hover:after, .previous-btn button:hover:before,
.send-btn button:hover:after, .save-btn button:hover:before {
  filter: invert(7%) sepia(73%) saturate(2463%) hue-rotate(213deg) brightness(95%) contrast(92%);
}

.formkit-outer[data-type="submit"] .formkit-wrapper {
  padding: 0 2em 1em 2em;
  display: flex;
}

.formkit-outer[data-type="submit"] .formkit-input {
  margin-left: auto;
  margin-right: 0;
}

details {
  border: 1px solid var(--gray);
  background: var(--gray-l);
  border-radius: .15em;
  padding: 1em;
  display: none;
}

.formkit-form > .formkit-messages {
  padding: 0 2em 0em 2em;
}
.formkit-form > .formkit-messages:last-child {
  padding: 0 2em 2em 2em;
}

.formkit-label {
  margin-bottom: 0.25rem;
}

[data-errors="true"] .formkit-label { color:var(--color-danger); }

.formkit-wrapper { max-width: 100%; }

button:hover, summary { cursor: pointer }

p small { color: #999; }


.message {
  background-color: var(--color-success);
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 1rem 0;
  color: white;
}

.message:before {
  content: "✔";
  margin-right: 5px;
  font-size: 1.5rem;
}

.message-warning {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid var(--color-danger);
  border-radius: 5px;
  margin: 1rem 0;
}

.message-warning:before {
  content: "⚠";
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-danger);
}


/* Footer nur für Bethel */
#footer {
  background-color: var(--col1);
  position: relative;
  color: white;
}
#footer::after {
  position: absolute;
  content: "";
  width: 15vw;
  height: 10vw;
  max-width: 296px;
  max-height: 193px;
  bottom: 0;
  right: 0;
  background-image: url("https://www.bethel-regional.de/files/bethel_theme/layout_bilder/bethel_signet_footer.svg");
  background-size: cover;
  pointer-events: none;
}

#footer a:hover, #footer a:focus {
  text-decoration-color: var(--col3);
}
.footer__content {
  margin-right: auto;
  margin-left: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer__logo {
  width: 40%;
  max-width: 260px;
}
.footer__infos {
  width: 60%;
}
.footer__infos a {
  color: var(--col2);
}
.footer__logo .image_container {
  max-width: 260px;
  margin-inline-start: 0;
}
#footer img {
  display: block;
}

.footer__navi ul {
  max-width: 250px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}
.footer__navi li {
  border-right: 1px solid var(--col2);
}
.footer__navi li:last-child {
  border-right: 0;
}
.footer__navi a {
  display: block;
  text-decoration: none;
  padding: 5px 10px;
  color: var(--col1);
  font-size: 0.8rem;
  line-height: 150%;
}

.footer__navi a:hover, .footer__navi a:focus {
  text-decoration: underline;
}
.footer__legal-content {
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding:20px;
  color: var(--col1);
}
.footer__copyright {
  font-size: 0.8rem;
  line-height: 150%;
  margin: 0;
  padding: 5px 0;
}


@media (max-width: 770px) {
  h1 {
    font-size: 1.6rem;
  }
  .footer__content {
    flex-wrap: wrap;
  }

  .footer__logo,
  .footer__infos {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  h1 { font-size: 1.5rem; }
  h2 { font-size: 1.2rem; line-height: 130%; }
  .steps {
    width: 40px;
  }
  .formkit-form {
    gap: 15px;
  }
  .form-body { padding: 1em; }

  .formkit-input {
    padding: 10px;
    font-size: 0.9rem;
  }
  .step-nav {
    flex-wrap: wrap;
  }

  .formkit-outer[data-type="submit"] .formkit-wrapper {
    padding: 0 1em 1em 1em;
    display: flex;
  }

  [data-type="button"] .formkit-input, [data-type="submit"] .formkit-input {
    padding: 10px 15px;
  }
  .formkit-form > .formkit-messages {
    padding: 0 1em 0em 1em;
  }
  .formkit-form > .formkit-messages:last-child {
    padding: 0 1em 1em 1em;
  }

}

@media (max-width: 470px) {
  html {
    font-size: 16px;
  }
  #header .pageTitle {
    max-width: 100%;
    order: 1;
  }
  #header h1 {
    text-align: center;
  }
  .logo {
    margin-left: auto;
    margin-right: auto;
    max-width: 150px;
  }
  #main {
    padding-left: 10px;
    padding-right: 5px;
  }

  ul {
    padding-left: 1rem;
  }

  #app .source-content {
    padding: 0.5em;
  }
  .formkit-form {
    gap: 5px;
  }
  .form-body {
    padding: 1em;
    box-shadow: none;
  }
  .formkit-input::placeholder,
  select.formkit-input[data-placeholder] {
    font-size: 0.8em;
  }
  .steps {
    width: 25px;
    min-width: 25px;
  }
  .step:before, .step--errors, .step--icon {
    border-width: 2px;
    height: 25px;
    width: 25px;
  }
  .step:after {
    left: 12px;
    width: 2px;
  }
  .step--errors, .step--icon {
    padding: 0;
  }

  [data-type="button"] .formkit-input, [data-type="submit"] .formkit-input {
    font-size: 1rem;
  }
  .step-nav .previous-btn, .step-nav .next-btn, .step-nav [data-type="button"] .formkit-input {
    width: 100%;
  }
  .step-nav .previous-btn button {
    justify-content: start;
  }
  .step-nav .next-btn button {
    justify-content: end;
  }
  #footer::after {
    width: 35vw;
    height: 35vw;
  }

}
</style>
